import React from 'react'
const Rectangle = props => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        x="1"
        y="1"
        width="9"
        height="9"
        rx="2"
        stroke="#FF6A3C"
        strokeWidth="2"
      />
    </svg>
  )
}

export default Rectangle

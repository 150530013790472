import * as styles from './HeroSection.module.scss'
import cn from 'classnames'

import React, { useRef, useState, useEffect, useMemo } from 'react'
import { useObserver } from 'utils'

import { SubMainTitle, MainTitle, LogosTicker } from 'ui'
import Header from 'components/Header'
import SquearesBackground from './SquearesBackground'

import siteConfig from 'site/settings/site_config.json'

import { useWindowSize } from 'utils'

const StaticBackground = () => <div className={styles.staticBg}></div>

const HeroSection = () => {
  const containerRef = useRef(null)
  const [isIntersecting] = useObserver(containerRef)
  const isScrollBg = !!containerRef?.current && !isIntersecting

  const [isStatic, setIsStatic] = useState(false)
  const [windowWidth] = useWindowSize()

  const Background = useMemo(
    () => (isStatic ? StaticBackground : SquearesBackground),
    [isStatic],
  )

  useEffect(() => {
    if (windowWidth <= 767) {
      setIsStatic(true)
    } else {
      setIsStatic(false)
    }
  }, [windowWidth])

  return (
    <section
      className={cn('hero', styles.section, {
        ['scrollBotBg']: !isStatic && isScrollBg,
      })}>
      <Background isScrollBg={isScrollBg} />
      <Header isTransparentOnTop />
      <div className={cn('container', styles.textContainer)} ref={containerRef}>
        <div className="row align-items-start">
          <MainTitle
            text={siteConfig.title}
            className={cn(styles.text, styles.mainTitle)}
          />
          <SubMainTitle
            text={siteConfig.description}
            className={cn(styles.text, styles.subtitle)}
          />
        </div>
      </div>
      <LogosTicker />
    </section>
  )
}
export default HeroSection

import React from 'react'
import cn from 'classnames'
import * as styles from './Item.module.scss'

import Rectangle from 'components/Icons/Rectangle'

const Item = ({ text, index }) => {
  return (
    <div className={styles.container}>
      <div className={styles.container__left}>
        <Rectangle
          className={cn(styles.container__left__icon, {
            [styles.filled]: index >= 1,
          })}
        />
        <Rectangle
          className={cn(styles.container__left__icon, {
            [styles.filled]: index >= 2,
          })}
        />
        <Rectangle
          className={cn(styles.container__left__icon, {
            [styles.filled]: index >= 3,
          })}
        />
        <Rectangle
          className={cn(styles.container__left__icon, {
            [styles.filled]: index >= 4,
          })}
        />
      </div>
      <p className={styles.container__right}>
        <span className={styles.container__right__text}>{text}</span>
      </p>
    </div>
  )
}

export default Item

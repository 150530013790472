import React from 'react'
import * as styles from './WhatNext.module.scss'

import { Title } from 'ui'

import Item from './Item'

import contact_menu from 'site/settings/contact_menu.json'

const WhatNext = () => (
  <div className={styles.container}>
    <Title
      variant="small"
      text={contact_menu.title}
      className={styles.container__title}
    />
    <div>
      <Item index={1} text={contact_menu.item_1} />
      <Item index={2} text={contact_menu.item_2} />
      <Item index={3} text={contact_menu.item_3} />
      <Item index={4} text={contact_menu.item_4} />
    </div>
  </div>
)

export default WhatNext

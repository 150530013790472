import React, { useState, useEffect } from 'react'

import HeroSection from './HeroSection'
import ProudOfSection from 'components/ProudOfSection'
import ButtonsCubeSection from 'components/ButtonsCubeSection'
import WeRockSection from './WeRockSection'
import ProcesesSection from './ProcesesSection'
import ReviewsSection from './ReviewsSection'
import FeedbackSection from './FeedbackSection'

import services_settings from 'site/services/services_settings.json'

import { useStaticQuery, graphql } from 'gatsby'

const FrontPage = () => {
  const [mapItems, setMapItems] = useState([])
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/(services)/" } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          nodes {
            fields {
              slug
            }
            frontmatter {
              title
              homepage_description
              homepage_icon {
                relativePath
              }
              homepage_square_rotation
            }
          }
        }
      }
    `,
  )

  useEffect(() => {
    setMapItems(
      allMarkdownRemark.nodes.map(
        ({
          fields: { slug },
          frontmatter: {
            title,
            homepage_description,
            homepage_icon: { relativePath },
            homepage_square_rotation,
          },
        }) => ({
          title,
          description: homepage_description,
          link: `/services${slug}`,
          rotation: homepage_square_rotation,
          icon: relativePath,
        }),
      ),
    )
  }, [allMarkdownRemark])

  return (
    <>
      <HeroSection />
      <ProudOfSection
        filterCallback={({ frontmatter }) => {
          const pages_to_show = frontmatter?.pages_to_show || []
          return pages_to_show.includes('Hero_Page')
        }}
        itemsToShow={4}
        className="proudof"
        subTitleClassName="grey-text"
      />
      <ButtonsCubeSection
        linkTo="/services"
        linkTitle="More about our services"
        title={services_settings.homepage_title}
        subTitle={services_settings.homepage_sub_title}
        mapItems={mapItems}
        className="services"
      />
      <WeRockSection />
      <ProcesesSection />
      <ReviewsSection />
      <FeedbackSection />
    </>
  )
}

export default FrontPage

import React, { useRef, useEffect, useMemo, useState, useCallback } from 'react'

import * as styles from './SquearesBackground.module.scss'
import { useWindowSize } from 'utils'

import cn from 'classnames'

const Square = ({ sideWidth, dataindex, hidden }) => {
  return (
    <div
      className={cn(styles.square, hidden ? styles.hidden : '')}
      data-index={dataindex}
      style={{ width: `${sideWidth}px`, height: `${sideWidth}px` }}></div>
  )
}

const opacity1 = '0.38'
const opacity2 = '0.3'
const opacity3 = '0.22'

const SquearesBackground = ({ isScrollBg }) => {
  const [windowWidth, windowHeight] = useWindowSize()
  const [squareWidth, setSquareWidth] = useState(0)
  const [rowCount, setRowCount] = useState(0)

  const [count, setCount] = useState(42) // 78

  const containerRef = useRef(null)
  const wrapperRef = useRef(null)
  const figureRef = useRef(null)

  const setOpacity = useCallback((node, opacityValue) => {
    node?.style?.setProperty('--opacity', opacityValue)
  }, [])

  const onMouseMove = useCallback(
    ({ clientX, clientY }) => {
      wrapperRef.current.style?.setProperty('--x', `${clientX}px`)
      wrapperRef.current.style?.setProperty('--y', `${clientY}px`)
      figureRef.current?.style?.setProperty('--x', `${clientX}px`)
      figureRef.current?.style?.setProperty('--y', `${clientY}px`)

      const currentCircle = document.elementFromPoint(clientX, clientY)
      const index = currentCircle.getAttribute('data-index')

      currentCircle.style?.setProperty('--opacity', opacity1)

      const clientWidth = containerRef.current.clientWidth
      const circlesNodes = containerRef.current.children
      const rowCount = ~~(clientWidth / squareWidth)

      const nodeRight = circlesNodes[+index + 1]
      const nodeLeft = circlesNodes[+index - 1]
      const nodeUnder = circlesNodes[+index + rowCount]
      const nodeUpper = circlesNodes[+index - rowCount]
      const nodeUpperT1L1 = circlesNodes[+index - 1 - rowCount]
      const nodeUpperT1R1 = circlesNodes[+index + 1 - rowCount]
      const nodeUnderB1L1 = circlesNodes[+index - 1 + rowCount]
      const nodeUnderB1R1 = circlesNodes[+index + 1 + rowCount]

      setOpacity(nodeRight, opacity2)
      setOpacity(nodeLeft, opacity2)
      setOpacity(nodeUnder, opacity2)
      setOpacity(nodeUpper, opacity2)
      setOpacity(nodeUpperT1L1, opacity2)
      setOpacity(nodeUpperT1R1, opacity2)
      setOpacity(nodeUnderB1L1, opacity2)
      setOpacity(nodeUnderB1R1, opacity2)

      const nodeRight2 = circlesNodes[+index + 2]
      const nodeLeft2 = circlesNodes[+index - 2]
      const nodeUnder2 = circlesNodes[+index + rowCount * 2]
      const nodeUpper2 = circlesNodes[+index - rowCount * 2]

      setOpacity(nodeRight2, opacity3)
      setOpacity(nodeLeft2, opacity3)
      setOpacity(nodeUnder2, opacity3)
      setOpacity(nodeUpper2, opacity3)
    },
    [squareWidth],
  )

  const squeresList = useMemo(() => {
    const list = []
    for (let i = 0; i < count; i++) {
      list.push(
        <Square
          key={`square-${i}`}
          rowCount={rowCount}
          sideWidth={squareWidth}
          dataindex={i}
          hidden={isScrollBg}
        />,
      )
    }
    return list
  }, [squareWidth, isScrollBg])

  useEffect(() => {
    figureRef.current?.style?.setProperty('--side', `${windowHeight}px`)
  }, [windowHeight])

  useEffect(() => {
    if (windowWidth < 769) {
      return
    }

    const timeout = setTimeout(() => {
      if (windowWidth < 998) {
        setCount(80)
        setSquareWidth(windowWidth / 8)
        return
      }
      // if (windowWidth < 1366) {
      //   setCount(42)
      //   setSquareWidth(windowWidth / 6)
      //   return
      // }
      setCount(120)
      setSquareWidth(windowWidth / 12)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [windowWidth])

  useEffect(() => {
    const clientWidth = containerRef.current.clientWidth

    setRowCount(~~(clientWidth / squareWidth))
  }, [squareWidth, rowCount])

  return (
    <>
      <div
        className={cn(styles.wrapper, !isScrollBg && styles.clipPath, 'hero', {
          ['hero__scrollBotBg']: isScrollBg,
        })}
        onMouseMove={onMouseMove}
        ref={wrapperRef}>
        <div className={styles.wrapper__background} ref={containerRef}>
          {squeresList}
        </div>
      </div>
      <div
        className={cn({
          [styles.figure]: !isScrollBg,
        })}
        ref={figureRef}></div>
    </>
  )
}

export default SquearesBackground

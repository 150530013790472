import React from 'react'

import { Title, SubTitle } from 'ui'
import SectionWithScrollBg from 'components/SectionWithScrollBg'
import FlipCards from './FlipCards'

import sliders_settings from 'site/sliders/settings.json'

const ProcesesSection = () => {
  return (
    <SectionWithScrollBg className="proceses" style={{ overflowX: 'hidden' }}>
      <div className="row">
        <div className="col-xxl-8 col-lg-8 col-md-12">
          <Title
            text={sliders_settings.title_research || ''}
            className="text-white"
          />
          {sliders_settings.sub_title_research && (
            <SubTitle
              text={sliders_settings.sub_title_research}
              className="grey-text"
            />
          )}
        </div>
      </div>
      <FlipCards />
    </SectionWithScrollBg>
  )
}
export default ProcesesSection

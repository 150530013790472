import React from 'react'
import * as styles from './FlipCards.module.scss'

import research_slider from 'site/sliders/research_slider.json'

import FlipCard from './FlipCard'

const FlipCards = () => (
  <div className={styles.container}>
    {research_slider?.research_slider?.map(
      (
        {
          title,
          description,
          card_label_left_1,
          card_label_left_2,
          card_label_right_1,
          card_label_right_2,
          card_side_label_right_top,
          card_side_label_right_bottom,
          card_side_label_left_top,
          card_side_label_left_bottom,
        },
        i,
      ) => (
        <FlipCard
          key={`research_slider-flip_card-${i}`}
          header={title}
          textBack={description}
          titleLeft={card_label_left_1}
          titleLeft2={card_label_left_2}
          titleRight={card_label_right_1}
          titleRight2={card_label_right_2}
          sideRightTopTitle={card_side_label_right_top}
          sideRightBottomTitle={card_side_label_right_bottom}
          sideLeftTopTitle={card_side_label_left_top}
          sideLeftBottomTitle={card_side_label_left_bottom}
        />
      ),
    )}
  </div>
)

export default FlipCards

import * as React from 'react'

import 'assets/style.scss'

import Layout from 'components/layout'
import SEO from 'components/seo'
import FrontPage from 'components/FrontPage'

import siteConfig from 'site/settings/site_config.json'

const PageIndex = ({ location }) => {
  return (
    <Layout
      location={location}
      noHeader={true}
      mainTagStyles={{ paddingTop: 0 }}>
      <SEO
        title={siteConfig.meta_title}
        description={siteConfig.meta_description}
        location={location.href}
      />
      <FrontPage />
    </Layout>
  )
}

export default PageIndex

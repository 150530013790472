import React from 'react'
const DashedLine = props => {
  return (
    <svg
      width="4"
      viewBox="0 0 4 456"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M2 0V456"
        stroke="#32343B"
        strokeWidth="3"
        strokeDasharray="12 16"
      />
    </svg>
  )
}

export default DashedLine

import * as styles from './FeedbackSection.module.scss'
import cn from 'classnames'

import React from 'react'

import { Title } from 'ui'
import SectionWithScrollBg from 'components/SectionWithScrollBg'
import Form from './Form'
import WhatNext from './WhatNext'

import contact_settings from 'site/settings/contact_settings.json'

const FeedbackSection = ({}) => {
  return (
    <SectionWithScrollBg className="feedback" id="hireUs">
      <div className="row">
        <div className="col-xxl-8 col-lg-8 col-md-12">
          <Title text={contact_settings.title} />
        </div>
      </div>
      <div className={cn('row', styles.content)}>
        <div className="col-xxl-6 col-lg-6 col-md-12">
          <Form />
        </div>
        <div className="col-xxl-1 col-lg-1 col-md-12"></div>
        <div className="col-xxl-5 col-lg-5 col-md-12">
          <WhatNext />
        </div>
      </div>
    </SectionWithScrollBg>
  )
}

export default FeedbackSection

import React, { useState } from 'react'
import * as styles from './FlipCard.module.scss'
import cn from 'classnames'

import Vector from 'components/Icons/Vector'
import DashedLine from 'components/Icons/DashedLine'

import { ActionButton } from 'ui'
import { useDelayUnmount } from 'utils'

const FlipCard = ({
  header,
  titleLeft,
  titleLeft2,
  titleRight,
  titleRight2,
  sideRightTopTitle,
  sideRightBottomTitle,
  sideLeftTopTitle,
  sideLeftBottomTitle,
  textBack,
}) => {
  const [isFlipped, setIsFlipped] = useState(false)
  const [hovered, setHovered] = useState(false)
  const shouldRenderButton = useDelayUnmount(hovered, 150)

  const handleFlip = () => {
    setHovered(false)
    setIsFlipped(prev => !prev)
  }

  const handleMouseEnter = () => setHovered(true)
  const handleMouseLeave = () => setHovered(false)

  return (
    <div className={styles.mainWrap}>
      <div
        className={styles.wrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <div className={styles.wrapper__card} onClick={handleFlip}>
          <DashedLine className={styles.wrapper__card__line} />
          <div className={cn(styles.wrapper__card__sideTitle, styles.topRight)}>
            <p className={styles.wrapper__card__sideTitle__title}>
              {sideRightTopTitle}
            </p>
            <Vector />
          </div>
          <div className={cn(styles.wrapper__card__sideTitle, styles.topLeft)}>
            <p className={styles.wrapper__card__sideTitle__title}>
              {sideLeftTopTitle}
            </p>
            <Vector className={styles.iconMobMirror} />
          </div>
          <div className={cn(styles.wrapper__card__sideTitle, styles.botLeft)}>
            <Vector />
            <p className={styles.wrapper__card__sideTitle__title}>
              {sideLeftBottomTitle}
            </p>
          </div>
          <div className={cn(styles.wrapper__card__sideTitle, styles.botRight)}>
            <Vector className={styles.iconMobMirror} />
            <p className={styles.wrapper__card__sideTitle__title}>
              {sideRightBottomTitle}
            </p>
          </div>
        </div>
        <div
          className={cn(styles.wrapper__front, {
            [styles.wrapper__front_flipped]: isFlipped,
          })}>
          <h5 className={styles.wrapper__front__title}>
            <p>{titleLeft}</p>
            {titleLeft2 && <p>{titleLeft2}</p>}
          </h5>
          <h5 className={styles.wrapper__front__title}>
            <p>{titleRight}</p>
            {titleRight2 && <p>{titleRight2}</p>}
          </h5>
        </div>

        <div
          className={cn(styles.wrapper__back, {
            [styles.wrapper__back_flipped]: isFlipped,
          })}
          onClick={handleFlip}>
          <div className={styles.wrapper__back__text}>
            <i></i>
            {textBack}
          </div>
        </div>
        {shouldRenderButton && (
          <ActionButton
            title="Flip"
            titleSecond="card"
            variant="secondary"
            onClick={handleFlip}
            isMounted={hovered}
            className={styles.wrapper__button}
          />
        )}
      </div>
      <div className={styles.header}>
        <h5 className={styles.header__title}>{header}</h5>
        <p className={styles.header__text}>{textBack}</p>
      </div>
    </div>
  )
}
export default FlipCard

import React from 'react'

import SectionWithScrollBg from 'components/SectionWithScrollBg'
import ClientsSwiper from 'components/ClientsSwiper'
import BlogItemsList from 'components/BlogItemsList'

import sliders_settings from 'site/sliders/settings.json'
import siteConfig from 'site/settings/site_config.json'

const ReviewsSection = () => {
  return (
    <SectionWithScrollBg className="reviews">
      <ClientsSwiper
        title={sliders_settings.title_review}
        subTitle={sliders_settings.sub_title_review}
      />
      <BlogItemsList
        filterCallback={({ frontmatter }) => {
          // console.log(frontmatter)
          // const pages_to_show = frontmatter?.pages_to_show || []
          // return pages_to_show.includes('Hero_Page')
          return true
        }}
        itemsToShow={2}
        title={siteConfig.blog_title}
        subTitle={siteConfig.blog_description}
        linkText={siteConfig.blog_link_text}
      />
    </SectionWithScrollBg>
  )
}

export default ReviewsSection

import React from 'react'

import benefits_settings from 'site/benefits/settings.json'
import benefits from 'site/benefits/benefits.json'

import benefits_cards_settings from 'site/benefits_cards/settings.json'
import benefits_cards from 'site/benefits_cards/benefits_cards.json'

import { Title, SubTitle } from 'ui'
import SectionWithScrollBg from 'components/SectionWithScrollBg'
import TextWithCubes from 'components/TextWithCubes'
import FixableCards from 'components/FixableCards'

const WeRockSection = () => {
  return (
    <SectionWithScrollBg className="werock">
      <div className="row">
        <div className="col-xxl-8 col-lg-8 col-md-12">
          <Title text={benefits_settings.title} />
          <SubTitle text={benefits_settings.sub_title} />
        </div>
      </div>
      <TextWithCubes
        title_1={benefits.title_1}
        sub_title_1={benefits.sub_title_1}
        title_2={benefits.title_2}
        sub_title_2={benefits.sub_title_2}
        title_3={benefits.title_3}
        sub_title_3={benefits.sub_title_3}
      />
      <div className="row">
        <div className="col-xxl-10 col-lg-10 col-md-12">
          <Title text={benefits_cards_settings.title} />
          <SubTitle text={benefits_cards_settings.sub_title} />
        </div>
      </div>
      <FixableCards mapItems={benefits_cards['Benefits Cards']} />
    </SectionWithScrollBg>
  )
}
export default WeRockSection

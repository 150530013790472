import React from 'react'
const Vector = props => {
  return (
    <svg
      width="170"
      height="12"
      viewBox="0 0 170 12"
      fill="#76777C"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M170 6L160 0.226497V11.7735L170 6ZM0 7H161V5H0L0 7Z" />
    </svg>
  )
}

export default Vector

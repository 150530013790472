// extracted by mini-css-extract-plugin
export var botLeft = "FlipCard-module--botLeft--5qfl1";
export var botRight = "FlipCard-module--botRight--XNkbN";
export var header = "FlipCard-module--header--wVdO6";
export var header__text = "FlipCard-module--header__text--6c9Pp";
export var header__title = "FlipCard-module--header__title--UQsU1";
export var iconMobMirror = "FlipCard-module--iconMobMirror--iLuj6";
export var mainWrap = "FlipCard-module--mainWrap--EKrb8";
export var topLeft = "FlipCard-module--topLeft--Bg0nP";
export var topRight = "FlipCard-module--topRight--r9e8N";
export var wrapper = "FlipCard-module--wrapper--ux31h";
export var wrapper__back = "FlipCard-module--wrapper__back--qvwNg";
export var wrapper__back__text = "FlipCard-module--wrapper__back__text--Pc3fg";
export var wrapper__back_flipped = "FlipCard-module--wrapper__back_flipped--0cX6n";
export var wrapper__button = "FlipCard-module--wrapper__button--bw0IF";
export var wrapper__card = "FlipCard-module--wrapper__card--AgBiu";
export var wrapper__card__line = "FlipCard-module--wrapper__card__line--JGi-Z";
export var wrapper__card__sideTitle = "FlipCard-module--wrapper__card__sideTitle--enVQx";
export var wrapper__card__sideTitle__title = "FlipCard-module--wrapper__card__sideTitle__title--ytM13";
export var wrapper__front = "FlipCard-module--wrapper__front--g9K8M";
export var wrapper__front__title = "FlipCard-module--wrapper__front__title--dxmMZ";
export var wrapper__front_flipped = "FlipCard-module--wrapper__front_flipped--55oak";